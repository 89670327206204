body {
  margin:0;
}
.spacedFilterNarrow {
  flex: 1;
  padding-right: 10px;
}
.spacedFilterWide {
  flex: 3;
  padding-right: 10px;
}
.filterTitle {
  margin-bottom: 5px;
  margin-top: 0;
}

.visualiserImageItemContainer {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}
.visualiserImageItemImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  position: absolute;
  -moz-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow:1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
}
.visualiserImageItemImage:hover {
  -moz-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow:1px 1px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
}

.visualiserDashboardFilters {
  left: 0;
  right: 0;
  width: 91%;
  margin-left: 4.5%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.centeredDiv {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 90%;
}

.buttonLink {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  width: 110%;
  margin-left: -5%;
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.entityWrapper {
  margin-bottom: 5px;
  margin-right: 5px;
}

.entitiesWrapper {
  margin-top: 5px;
  display: flex;
  flex-shrink: 0;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.entityTitle {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  font-size: 9px;
  line-height: 12px;
}

.entityValue {
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
}

.detailImage {
  display: block;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 300px;
  height: 300px;
}

.visualiserDashboardWrapper {
  margin-top: 15px;
}

.entityFilterWrapper {
  display: flex;
  flex-direction: row;
}
.entityTextFilter {
  flex: 3;
}
.entityTextFilter input[class*="input"] {
  border-radius: 2px 0 0 2px !important;
}
.entitySelect {
  flex: 1;
}
.entitySelect button[class*="button-trigger"] {
  border-radius: 0 2px 2px 0 !important;
  border-left: none !important;
}

.configRow {
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.configLeft {
  display: flex;
  justify-content: flex-start;
}

.configRight {
  margin-top: auto;
}

.sliderContainer {
  width: 200px;
  padding-left: 15px;
}

.segmentedControlContainer {
  margin-top: auto;
}

.imageIdP {
  word-wrap: break-word;
  margin-bottom: 0;
}

.numberOfRequestsP {
  margin-top: 0;
  word-wrap: break-word;
}

.visualiserGrid {
  display: grid;
  grid-gap: 20px;
}